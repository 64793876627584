.connected-item {
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;

  .header {
    .title {
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px; /* 100% */
      letter-spacing: -1.05px;
      color: $primary-content;
      display: block;
      width: 100%;
      text-align: center;
      margin: 30px auto 24px;
      max-width: 472px;
    }
  }

  .service-providers-wrapper {
    margin: 0 auto;
    max-width: 472px;
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .connected-item {
    padding: 0;
    display: contents;

    .header {
      position: sticky;
      top: 0;
      z-index: 1000;
      display: flex;
      flex-direction: column;
      gap: 24px;
      padding: 16px 24px 24px 24px;
      background: $white;

      button {
        width: max-content;
      }

      .title {
        display: flex;
        justify-content: flex-start;
        margin: unset;
      }

      @media only screen and (max-width: 767px) {
        padding: 16px 24px 10px 24px;

        .tab-wrapper {
          margin: inherit;
          padding: 0;
        }
      }
    }

    .service-providers-wrapper {
      margin: unset;
      max-width: 100%;
      padding: 0 12px 16px 12px;
    }
  }
}
