.short-card {
  padding: 12px;
  padding-bottom: 0;
  border-radius: 10px;
  background-color: $white;

  .content-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 8px;
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 8px;

    .content-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .title {
        color: $primary;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: -0.5px;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .additional-info {
        color: $content-secondary;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: -0.5px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: flex;
        flex-direction: row;
        gap: 8px;
        margin-top: 2px;
      }
    }
  }

  .dynamic-content {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 8px;
  }

  .divider {
    margin-top: 11px;
    height: 1px;
    background-color: $gray-border-line;
    width: 100%;
    display: block;

    &.none {
      background-color: transparent;
    }
  }

  &:hover {
    background-color: $gray-background-hover;
    cursor: pointer;

    .avatar {
      background-color: $white;
    }

    .divider {
      background-color: transparent;
    }

    .label.inform {
      background-color: $white;
    }
  }

  &.selected {
    @media only screen and (min-width: 767px) {
      background-color: $gray-background-active;

      .avatar {
        background-color: $white;
      }

      .divider {
        background-color: transparent;
      }

      .label.inform {
        background-color: $white;
      }
    }
  }

  &.no-hover {
    &:hover {
      background-color: inherit;
      cursor: default;
  
      .avatar {
        background-color: $gray-background-hover;
      }
  
      .divider {
        background-color: $gray-border-line;
      }
  
      .label.inform {
        background-color: inherit;
      }
    }
  }

}
