@import "variables";

#toast-container {
  top: 48px !important;
  width: calc(100% - 280px);

  @media screen and (max-width: 767px) {
    width: 100%;
  }

  .toast-error,
  .toast-success {
    display: flex;
    width: 328px;
    padding: 12px;
    justify-content: space-between;
    align-items: center;
    gap: var(--spacing-8, 8px);
    border-radius: var(--radius-sm, 6px);
    background: $primary;

    &::after {
      content: "\e90f";
      font-size: 16px;
    }

    &.icon-checked-circle::before {
      font-size: 22px;
      color: $white;
      content: "\e927";
    }

    &.icon-alert-triangle::before {
      font-size: 22px;
      color: $white;
      content: "\e935";
    }

    .toast-message,
    .toast-title {
      width: 100%;
      font-family: "Inter", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.5px;
    }
  }

  .toast-error {
    background: $system-cancelled;
  }
}
