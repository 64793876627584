textarea,
input {
  height: 56px;
  width: 100%;
  padding: 16px 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 10px;
  border: 1px solid $gray-background-active;
  background: $white;
  outline: none;
  color: $primary;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.16px;

  &::placeholder {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.16px;
    color: $gray-quaternary;
    text-transform: capitalize;
  }

  &:focus, &:focus-visible, &:active {
    border: 2px solid $accent;
  }

  &:disabled {
    border: 1px solid $gray-background-active;
    opacity: 0.5;
  }

  &.invalid,
  &.ng-invalid:not(.ng-pristine):not(.ng-untouched) {
    border: 2px solid $system-destructive;
  }

  &.with-icon {
    padding-left: 44px;
  }

  &.with-action-icon {
    padding-right: 44px;
  }
}

textarea {
  resize: none;
  height: 112px;
}

.input-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 4px;

  label {
    display: flex;
    flex-direction: row;
    gap: 4px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.5px;
    color: $content-secondary;

    span {
      color: $system-destructive;
    }
  }

  .infoText {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.5px;
    color: $content-secondary;
    margin-top: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;

    i {
      position: relative;
      margin-right: 4px;
      font-size: 16px;
    }
  }

  .main-icon {
    position: absolute;
    top: 36px;
    font-size: 24px;
    left: 13px;
  }

  .action-icon {
    position: absolute;
    top: 40px;
    right: 12px;

    i {
      font-size: 24px;
    }

    &:hover {
      background: none;
      cursor: pointer;
    }
  }

  &.disabled {
    label {
      opacity: 0.5;
    }

    .infoText {
      opacity: 0.5;
    }

    .main-icon {
      opacity: 0.5;
    }
  }

  &.errors {
    label {
      color: $system-destructive;
    }

    .infoText {
      color: $system-destructive;
    }
  }
}

.radio-button {
  cursor: pointer;
  font-weight: 600;
  color: $primary;
  line-height: 20px;
  letter-spacing: -0.5px;
  font-size: 16px;
  display: inline-block;
}

input[type=radio] {
  box-sizing: border-box;
  visibility: hidden;
  width: 0;
  height: 0;
}

.radio-button__input {
  opacity: 0;
  position: absolute;
}

.circle {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 8px;
  vertical-align: middle;
  background-color: inherit;
  color: $primary;
  border: 1.5px solid $gray-background-active;
  border-radius: 16px;
}

.radio-button__input:checked + .circle:after {
  content: "";
  display: block;
  position: absolute;
  top: 6px;
  left: 6px;
  width: 9px;
  height: 9px;
  background-color: $primary;
  border-radius: 12px;
}

.radio-button__input:checked + .circle {
  border-color: $primary;
}
