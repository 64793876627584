// Mat Button
.mdc-button {
    display: inline-block !important;
    padding: 14px 35px !important;
    border-radius: 5px !important;
    min-width: unset !important;
    height: auto !important;
    min-height: unset;
    width: auto;
    font: {
        size: var(--fontSize) !important;
        weight: 500 !important;
    };
    &.natodi {
        --mat-mdc-button-persistent-ripple-color: var(--heraBlueColor);

        &.mat-unthemed {
            --mdc-text-button-label-text-color: var(--heraBlueColor);
        }
    }
    &.fw-bold {
        font-weight: 700 !important;
    }
    &.fw-semibold {
        font-weight: 600 !important;
    }
    &.d-block {
        text-align: center !important;
        display: block !important;
        width: 100% !important;
    }
    &.small {
        padding: 11px 22px !important;
        font: {
            size: 14px !important;
            weight: 600 !important;
        };
    }
}
.mat-mdc-raised-button {
    &.natodi {
        --mdc-protected-button-container-color: var(--heraBlueColor);

        &.mat-unthemed {
            --mdc-protected-button-label-text-color: var(--whiteColor);
        }
    }
    &.gray {
        --mdc-protected-button-container-color: #EDEFF5;

        &.mat-unthemed {
            --mdc-protected-button-label-text-color: var(--blackColor);
        }
    }
}
.mat-mdc-unelevated-button {
    &.natodi {
        &.mat-unthemed {
            --mdc-filled-button-container-color: var(--heraBlueColor);
            --mdc-filled-button-label-text-color: var(--whiteColor);
        }
    }
    &.gray {
        &.mat-unthemed {
            --mdc-filled-button-container-color: #EDEFF5;
            --mdc-filled-button-label-text-color: var(--blackColor);
        }
    }
}
.mat-mdc-icon-button {
    &.natodi {
        --mdc-icon-button-icon-color: var(--heraBlueColor);
    }
}
.mat-mdc-fab, .mat-mdc-mini-fab {
    &.natodi {
        --mdc-fab-container-color: var(--heraBlueColor);
        --mdc-fab-icon-color: var(--whiteColor);
        --mat-mdc-fab-color: var(--whiteColor);
    }
    &.gray {
        --mdc-fab-container-color: #EDEFF5;
        --mdc-fab-icon-color: var(--blackColor);
        --mat-mdc-fab-color: var(--blackColor);
    }
}
a {
    &.mat-mdc-icon-button, &.mat-mdc-fab, &.mdc-fab--mini {
        &:hover {
            color: var(--blackColor);
        }
    }
}

// Mat List
.mat-mdc-list-option, .mat-mdc-list-option {
    &.mat-accent {
        .mdc-list-item__start, .mdc-list-item__end {
            --mdc-radio-selected-focus-icon-color: var(--heraBlueColor);
            --mdc-radio-selected-hover-icon-color: var(--heraBlueColor);
            --mdc-radio-selected-icon-color: var(--heraBlueColor);
            --mdc-radio-selected-pressed-icon-color: var(--heraBlueColor);
            --mat-mdc-radio-checked-ripple-color: var(--heraBlueColor);
            --mdc-checkbox-selected-focus-icon-color: var(--heraBlueColor);
            --mdc-checkbox-selected-hover-icon-color: var(--heraBlueColor);
            --mdc-checkbox-selected-icon-color: var(--heraBlueColor);
            --mdc-checkbox-selected-pressed-icon-color: var(--heraBlueColor);
        }
    }
}
.mdc-list-item--selected, .mdc-list-item--activated {
    .mdc-list-item__primary-text {
        color: var(--heraBlueColor);
    }
}

// Mat Text Field
.mdc-text-field--focused {
    &:not(.mdc-text-field--disabled) {
        .mdc-floating-label {
            color: var(--heraBlueColor);
        }
    }
}
.mdc-text-field--filled {
    .mdc-line-ripple {
        &::after {
            border-bottom-color: var(--heraBlueColor);
        }
    }
}

// Mat Select

.mat-pseudo-checkbox-minimal {
    &.mat-pseudo-checkbox-checked {
        &::after {
            width: 10px;
            height: 5px;
        }
    }
}

// Badge
.badge {
    --badge-padding-x: 12px;
    --badge-padding-y: 0.40em;
    --badge-font-size: 13px;
    --badge-font-weight: 600;
    --badge-color: #fff;
    --badge-border-radius: 0.375rem;
    display: inline-block;
    padding: var(--badge-padding-y) var(--badge-padding-x);
    font-size: var(--badge-font-size);
    font-weight: var(--badge-font-weight);
    line-height: 1;
    color: var(--badge-color);
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: var(--badge-border-radius);
}
.text-bg-natodi {
    color: #fff!important;
    background-color: var(--heraBlueColor) !important;
}
.text-bg-primary {
    color: #fff!important;
    background-color: RGBA(13,110,253,var(--bg-opacity,1))!important;
}
.text-bg-secondary {
    color: #fff!important;
    background-color: RGBA(108,117,125,var(--bg-opacity,1))!important;
}
.text-bg-success {
    color: #fff!important;
    background-color: RGBA(25,135,84,var(--bg-opacity,1))!important;
}
.text-bg-danger {
    color: #fff!important;
    background-color: RGBA(220,53,69,var(--bg-opacity,1))!important;
}
.text-bg-warning {
    color: #000!important;
    background-color: RGBA(255,193,7,var(--bg-opacity,1))!important;
}
.text-bg-info {
    color: #000!important;
    background-color: RGBA(13,202,240,var(--bg-opacity,1))!important;
}
.text-bg-light {
    color: #000!important;
    background-color: RGBA(248,249,250,var(--bg-opacity,1))!important;
}
.text-bg-dark {
    color: #fff!important;
    background-color: RGBA(33,37,41,var(--bg-opacity,1))!important;
}
.text-outline-natodi {
    color: var(--heraBlueColor) !important;
    border: 1px solid var(--heraBlueColor) !important;
}
.text-outline-primary {
    color: RGBA(13,110,253,var(--bg-opacity,1))!important;
    border: 1px solid RGBA(13,110,253,var(--bg-opacity,1))!important;
}
.text-outline-secondary {
    color: RGBA(108,117,125,var(--bg-opacity,1))!important;
    border: 1px solid RGBA(108,117,125,var(--bg-opacity,1))!important;
}
.text-outline-success {
    color: RGBA(25,135,84,var(--bg-opacity,1))!important;
    border: 1px solid RGBA(25,135,84,var(--bg-opacity,1))!important;
}
.text-outline-danger {
    color: RGBA(220,53,69,var(--bg-opacity,1))!important;
    border: 1px solid RGBA(220,53,69,var(--bg-opacity,1))!important;
}
.text-outline-warning {
    color: RGBA(255,193,7,var(--bg-opacity,1))!important;
    border: 1px solid RGBA(255,193,7,var(--bg-opacity,1))!important;
}
.text-outline-info {
    color: RGBA(13,202,240,var(--bg-opacity,1))!important;
    border: 1px solid RGBA(13,202,240,var(--bg-opacity,1))!important;
}
.text-outline-light {
    color: RGBA(248,249,250,var(--bg-opacity,1))!important;
    border: 1px solid RGBA(248,249,250,var(--bg-opacity,1))!important;
}
.text-outline-dark {
    color: RGBA(33,37,41,var(--bg-opacity,1))!important;
    border: 1px solid RGBA(33,37,41,var(--bg-opacity,1))!important;
}
.text-soft-natodi {
    color: var(--heraBlueColor) !important;
    background-color: rgba(117, 127, 239, 0.1) !important;
}
.text-soft-primary {
    color: RGBA(13,110,253,var(--bg-opacity,1))!important;
    background-color: #cfe2ff !important;
}
.text-soft-secondary {
    color: RGBA(108,117,125,var(--bg-opacity,1))!important;
    background-color: #f8f9fa !important;
}
.text-soft-success {
    color: RGBA(25,135,84,var(--bg-opacity,1))!important;
    background-color: #d1e7dd !important;
}
.text-soft-danger {
    color: RGBA(220,53,69,var(--bg-opacity,1))!important;
    background-color: #f8d7da !important;
}
.text-soft-warning {
    color: RGBA(255,193,7,var(--bg-opacity,1))!important;
    background-color: #fff3cd !important;
}
.text-soft-info {
    color: RGBA(13,202,240,var(--bg-opacity,1))!important;
    background-color: #cff4fc !important;
}
.text-soft-light {
    color: #000000 !important;
    background-color: #fcfcfd !important;
}
.text-soft-dark {
    color: RGBA(33,37,41,var(--bg-opacity,1))!important;
    background-color: #ced4da !important;
}

// Mat Checkbox
.mat-mdc-checkbox {
    &.mat-accent {
        --mdc-checkbox-selected-focus-icon-color: var(--heraBlueColor);
        --mdc-checkbox-selected-hover-icon-color: var(--heraBlueColor);
        --mdc-checkbox-selected-icon-color: var(--heraBlueColor);
        --mdc-checkbox-selected-pressed-icon-color: var(--heraBlueColor);

        .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
            background: var(--heraBlueColor);
        }
        &.primary {
            --mdc-checkbox-selected-focus-icon-color: #0a58ca;
            --mdc-checkbox-selected-hover-icon-color: #0a58ca;
            --mdc-checkbox-selected-icon-color: #0a58ca;
            --mdc-checkbox-selected-pressed-icon-color: #0a58ca;

            .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
                background: #0a58ca;
            }
        }
        &.secondary {
            --mdc-checkbox-selected-focus-icon-color: #6c757d;
            --mdc-checkbox-selected-hover-icon-color: #6c757d;
            --mdc-checkbox-selected-icon-color: #6c757d;
            --mdc-checkbox-selected-pressed-icon-color: #6c757d;

            .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
                background: #6c757d;
            }
        }
        &.success {
            --mdc-checkbox-selected-focus-icon-color: #146c43;
            --mdc-checkbox-selected-hover-icon-color: #146c43;
            --mdc-checkbox-selected-icon-color: #146c43;
            --mdc-checkbox-selected-pressed-icon-color: #146c43;

            .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
                background: #146c43;
            }
        }
        &.info {
            --mdc-checkbox-selected-focus-icon-color: #087990;
            --mdc-checkbox-selected-hover-icon-color: #087990;
            --mdc-checkbox-selected-icon-color: #087990;
            --mdc-checkbox-selected-pressed-icon-color: #087990;

            .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
                background: #087990;
            }
        }
        &.warning {
            --mdc-checkbox-selected-focus-icon-color: #997404;
            --mdc-checkbox-selected-hover-icon-color: #997404;
            --mdc-checkbox-selected-icon-color: #997404;
            --mdc-checkbox-selected-pressed-icon-color: #997404;

            .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
                background: #997404;
            }
        }
        &.danger {
            --mdc-checkbox-selected-focus-icon-color: #b02a37;
            --mdc-checkbox-selected-hover-icon-color: #b02a37;
            --mdc-checkbox-selected-icon-color: #b02a37;
            --mdc-checkbox-selected-pressed-icon-color: #b02a37;

            .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
                background: #b02a37;
            }
        }
        &.light {
            --mdc-checkbox-selected-focus-icon-color: #6c757d;
            --mdc-checkbox-selected-hover-icon-color: #6c757d;
            --mdc-checkbox-selected-icon-color: #6c757d;
            --mdc-checkbox-selected-pressed-icon-color: #6c757d;

            .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
                background: #6c757d;
            }
        }
        &.dark {
            --mdc-checkbox-selected-focus-icon-color: #495057;
            --mdc-checkbox-selected-hover-icon-color: #495057;
            --mdc-checkbox-selected-icon-color: #495057;
            --mdc-checkbox-selected-pressed-icon-color: #495057;

            .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
                background: #495057;
            }
        }
    }
}

// Mat Radio
.mat-mdc-radio-button {
    &.mat-accent {
        --mdc-radio-selected-focus-icon-color: var(--heraBlueColor);
        --mdc-radio-selected-hover-icon-color: var(--heraBlueColor);
        --mdc-radio-selected-icon-color: var(--heraBlueColor);
        --mdc-radio-selected-pressed-icon-color: var(--heraBlueColor);
        --mat-mdc-radio-checked-ripple-color: var(--heraBlueColor);

        &.primary {
            --mdc-radio-selected-focus-icon-color: #0a58ca;
            --mdc-radio-selected-hover-icon-color: #0a58ca;
            --mdc-radio-selected-icon-color: #0a58ca;
            --mdc-radio-selected-pressed-icon-color: #0a58ca;
            --mat-mdc-radio-checked-ripple-color: #0a58ca;
        }
        &.secondary {
            --mdc-radio-selected-focus-icon-color: #6c757d;
            --mdc-radio-selected-hover-icon-color: #6c757d;
            --mdc-radio-selected-icon-color: #6c757d;
            --mdc-radio-selected-pressed-icon-color: #6c757d;
            --mat-mdc-radio-checked-ripple-color: #6c757d;
        }
        &.success {
            --mdc-radio-selected-focus-icon-color: #146c43;
            --mdc-radio-selected-hover-icon-color: #146c43;
            --mdc-radio-selected-icon-color: #146c43;
            --mdc-radio-selected-pressed-icon-color: #146c43;
            --mat-mdc-radio-checked-ripple-color: #146c43;
        }
        &.info {
            --mdc-radio-selected-focus-icon-color: #087990;
            --mdc-radio-selected-hover-icon-color: #087990;
            --mdc-radio-selected-icon-color: #087990;
            --mdc-radio-selected-pressed-icon-color: #087990;
            --mat-mdc-radio-checked-ripple-color: #087990;
        }
        &.warning {
            --mdc-radio-selected-focus-icon-color: #997404;
            --mdc-radio-selected-hover-icon-color: #997404;
            --mdc-radio-selected-icon-color: #997404;
            --mdc-radio-selected-pressed-icon-color: #997404;
            --mat-mdc-radio-checked-ripple-color: #997404;
        }
        &.danger {
            --mdc-radio-selected-focus-icon-color: #b02a37;
            --mdc-radio-selected-hover-icon-color: #b02a37;
            --mdc-radio-selected-icon-color: #b02a37;
            --mdc-radio-selected-pressed-icon-color: #b02a37;
            --mat-mdc-radio-checked-ripple-color: #b02a37;
        }
        &.light {
            --mdc-radio-selected-focus-icon-color: #6c757d;
            --mdc-radio-selected-hover-icon-color: #6c757d;
            --mdc-radio-selected-icon-color: #6c757d;
            --mdc-radio-selected-pressed-icon-color: #6c757d;
            --mat-mdc-radio-checked-ripple-color: #6c757d;
        }
        &.dark {
            --mdc-radio-selected-focus-icon-color: #495057;
            --mdc-radio-selected-hover-icon-color: #495057;
            --mdc-radio-selected-icon-color: #495057;
            --mdc-radio-selected-pressed-icon-color: #495057;
            --mat-mdc-radio-checked-ripple-color: #495057;
        }
    }
}

// Mat Divider
.mat-divider {
    border-top-color: rgba(0, 0, 0, .05);
}

// Mat Tabs
.mat-mdc-tab, .mat-mdc-tab-link {
    &:not(.mat-mdc-tab-disabled) {
        &.mdc-tab--active {
            .mdc-tab__text-label {
                color: var(--heraBlueColor);
            }
        }
        .mdc-tab-indicator__content--underline {
            border-color: var(--heraBlueColor);
        }
    }
}

// Mat Stepper
.mat-step-header {
    .mat-step-icon-selected, .mat-step-icon-state-done, .mat-step-icon-state-edit {
        background-color: var(--heraBlueColor);
    }
}

// Blockquotes
.blockquote {
    margin-bottom: 1rem;
    font-size: 1.25rem;

    &>:last-child {
        margin-bottom: 0;
    }
}
blockquote {
    margin: 0 0 1rem;
}
.blockquote-footer {
    font-size: .875em;
    color: #6c757d;
    margin: {
        top: -.5rem;
        bottom: 1rem;
    };
    &::before {
        content: "— ";
    }
}

// Timeline
.natodi-timeline {
    li {
        margin-bottom: 20px;
        padding: {
            bottom: 20px;
            left: 50px;
        };
        .icon {
            left: 0;
            top: 2px;
            position: absolute;
        }
        &::after {
            right: 0;
            bottom: 0;
            width: 98.5%;
            content: '';
            height: 1px;
            position: absolute;
            background: #F7FAFF;
        }
        &::before {
            background: #F7FAFF;
            border-radius: 5px;
            position: absolute;
            height: 75.5%;
            content: "";
            width: 3px;
            left: 1px;
            top: 25px;
        }
        &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;

            &::after {
                display: none;
            }
        }
    }
}
.timeline {
    position: relative;

    &::before {
        top: 0;
        left: 50%;
        width: 2px;
        content: '';
        height: 100%;
        margin: 0 0 0 -1px;
        position: absolute;
        background: rgba(0, 0, 0, .03);
    }
    .event {
        margin-bottom: 25px;
        position: relative;

        &:after {
            display: block;
            content: '';
            clear: both;
        }
        .inner {
            width: 45%;
            float: left;
            text-align: right;
            border-radius: 5px;

            .date {
                background: var(--heraBlueColor);
                color: var(--whiteColor);
                margin: 0 0 0 -25px;
                text-align: center;
                border-radius: 50%;
                position: absolute;
                line-height: 46px;
                display: block;
                height: 50px;
                width: 50px;
                left: 50%;
                top: 0;
            }
            .d-flex {
                justify-content: end;
            }
        }
        &:nth-child(2n+2) {
            .inner {
                float: right;
                text-align: left;
                
                .d-flex {
                    justify-content: unset;
                }
            }
        }
    }
}

// Mat Table
.mat-elevation-z8 {
    box-shadow: unset;

    .mat-mdc-paginator-outer-container {
        margin-top: 10px;

        .mat-mdc-paginator-container {
            padding: 0;
            min-height: auto;
            justify-content: space-between;

            .mat-mdc-paginator-page-size-label {
                margin: 0 5px 0 0;
                color: var(--paragraphColor);
            }
            .mdc-text-field {
                padding: {
                    left: 0 !important;
                    right: 0 !important;
                };
            }
            .mat-mdc-select-value {
                width: auto;
                margin-right: 5px;
            }
            .mat-mdc-select-min-line {
                color: var(--blackColor);
                font-weight: 600;
            }
            .mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
                min-height: auto;
                padding: {
                    top: 0;
                    bottom: 0;
                };
            }
            .mat-mdc-select-arrow {
                color: var(--paragraphColor);
            }
            .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading, .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch, .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
                border: none !important;
            }
            .mat-mdc-paginator-range-label {
                color: var(--paragraphColor);
                margin-left: 0;
            }
            .mat-mdc-icon-button {
                &.mat-mdc-button-base {
                    width: 40px;
                    height: 40px;
                    padding: 6px;
                }
            }
        }
    }
}
.mat-mdc-elevation-specific {
    &.mat-elevation-z8 {
        box-shadow: unset;
    }
}
.mat-mdc-table {
    width: 100%;
    background: var(--whiteColor);

    .mdc-data-table__header-row {
        height: auto;
        background-color: #f7faff;

        .mdc-data-table__header-cell {
            color: var(--blackColor);
            border-bottom: none;
            padding: 15px 20px;
            position: relative;
            
            &:last-child {
                &::after {
                    display: none;
                }
            }
            &::after {
                top: 50%;
                right: 0;
                width: 1px;
                content: '';
                height: 17px;
                position: absolute;
                background-color: #e7ebf5;
                transform: translateY(-50%);
            }
        }
    }
    .mdc-data-table__row {
        height: auto;

        &:hover {
            background-color: #e8eaf6 !important;
            cursor: pointer;
          }

        .mdc-data-table__cell {
            padding: 15px 20px;
            color: var(--paragraphColor);
            border-bottom-color: #eeeeee;
        }
        .mat-mdc-footer-cell {
            border-top: 1px solid #eeeeee;
        }
    }
}

@media only screen and (max-width : 767px) {

    // Mat Button
    .mdc-button {
        padding: 12px 25px !important;
        font-size: 14px !important;
    }

    // Timeline
    .natodi-timeline {
        li {
            padding-left: 40px;
        }
    }
    .timeline {
        &::before {
            left: 19px;
        }
        .event {
            .inner {
                width: 100%;
                float: unset;
                text-align: left;
                padding-left: 55px;
    
                .date {
                    line-height: 38px;
                    height: 40px;
                    width: 40px;
                    margin: 0;
                    left: 0;
                }
                .d-flex {
                    justify-content: unset;
                }
            }
            &:nth-child(2n+2) {
                .inner {
                    float: unset;
                    text-align: left;
                }
            }
        }
    }
}

@media only screen and (min-width : 768px) and (max-width : 991px) {

    // Mat Button
    .mdc-button {
        font-size: 14px !important;
    }

}