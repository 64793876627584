@font-face {
  font-family: 'Inter';
  font-weight: 400;
  font-style: normal;
  src: url('../../assets/fonts/inter/Inter-Regular/Inter-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 500;
  font-style: normal;
  src: url('../../assets/fonts/inter/Inter-Medium/Inter-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 600;
  font-style: normal;
  src: url('../../assets/fonts/inter/Inter-SemiBold/Inter-SemiBold.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 700;
  font-style: normal;
  src: url('../../assets/fonts/inter/Inter-Bold/Inter-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 800;
  font-style: normal;
  src: url('../../assets/fonts/inter/Inter-Bold/Inter-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 900;
  font-style: normal;
  src: url('../../assets/fonts/inter/Inter-Black/Inter-Black.woff2') format('woff2');
}
