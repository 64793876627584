.swing-animate {
  animation: swing ease-in-out 0.5s 1 alternate;
}

@keyframes swing {
  0%,
  30%,
  50%,
  70%,
  100% {
    transform: rotate(0deg);
  }

  10% {
    transform: rotate(10deg);
  }

  40% {
    transform: rotate(-10deg);
  }

  60% {
    transform: rotate(5deg);
  }

  80% {
    transform: rotate(-5deg);
  }
}

@keyframes pulse {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
}

@keyframes slide-out {
  0% {
    transform: translateX(-15%) rotate(180deg);
  }
  100% {
    transform: translateX(15%) rotate(180deg);
  }
}
